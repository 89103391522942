import React, { forwardRef } from 'react'
import { Row, Col } from '@kogk/common'

export default forwardRef(({ children, ...rest }, ref) => {
  return (
    <Row className='mt-1 mt-md-3'>
      <Col ref={ref} offset={{ lg: 2 }} col={{ lg: 8 }} {...rest}>
        {children}
      </Col>
    </Row>
  )
})
