import React from 'react'
import GridLayout from '@cmp/site/GridLayout'

const SliceWrapper = ({
  shouldMove,
  children,
  fullBleed = false,
  ...props
}) => {
  return (
    <article {...props}>
      {condWrap(!fullBleed, children, content => (
        <GridLayout>{content}</GridLayout>
      ))}
    </article>
  )
}

export default SliceWrapper

const condWrap = (cond, content, wrapper) => {
  return cond ? wrapper(content) : content
}
